import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'

import App from './App.vue'
import router from './router'
import store from './store'

import 'vue-toast-notification/dist/theme-sugar.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'

Vue.config.productionTip = false

Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('multiselect', Multiselect)

new Vue({
  router,
  store,
  created: function() {
    this.$store.dispatch("init");
  },
  render: h => h(App)
}).$mount('#app')
