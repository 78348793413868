import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import jwt_decode from "jwt-decode";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: null,
    activeTeam: null,
    teams: [],
    accessToken: localStorage.getItem('token'),
    loggedIn: false,
    reports: [],
    projects: [],
    assignee: []
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setLoggedIn(state, value) {
      state.loggedIn = value
    },
    setTeams(state, val) {
      state.teams = val
    },
    setReports(state, val) {
      state.reports = val
    },
    setActiveTeam(state, val) {
      state.activeTeam = val
    },
    setAccessToken(state, val) {
      state.accessToken = val
    },
    setProjects(state, val) {
      state.projects = val
    },
    setAssignee(state, val) {
      state.assignee = val
    }
  },
  actions: {
    updateUserProfile({ commit, dispatch }, user) {
      commit('setUserProfile', user)
      commit('setLoggedIn', user !== null)
      dispatch('getTeams', user.userId)

      // if (router.history.current.fullPath !== '/') {
      //   router.push('/')
      // }
    },
    getTeams({ commit, state }, userId) {
      fetch(`${process.env.VUE_APP_API_URL}user/${userId}/teams`,
        { headers: { 'authorization': `Bearer ${state.accessToken}` } })
        .then(response => {
          if (response.status == 401 || response.status == 403) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(teams => {
          commit('setTeams', teams)
          commit('setActiveTeam', teams[0])
         
        })
        .catch(async (error) => {
          console.log(error.status)
          if (error.status == 401 || error.status == 403) {

            if (router.history.current.fullPath !== '/login') {
              router.push('/login')
            }
            commit('setAccessToken', '')
            localStorage.removeItem('token')
          }
        })
    },

    getProjects({ commit, state }) {
      fetch(`${process.env.VUE_APP_API_URL}jira/projects`,
        { headers: { 'authorization': `Bearer ${state.accessToken}` } })
        .then(response => {
          if (response.status == 401 || response.status == 403) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(projects => {
          commit('setProjects', projects)
        })
        .catch(async (error) => {
          if (error.status == 401 || error.status == 403) {

            if (router.history.current.fullPath !== '/login') {
              router.push('/login')
            }
            commit('setAccessToken', '')
            localStorage.removeItem('token')
          }
        })
    },

    getAssignee({ commit, state }) {
      fetch(`${process.env.VUE_APP_API_URL}jira/assignee`,
        { headers: { 'authorization': `Bearer ${state.accessToken}` } })
        .then(response => {
          if (response.status == 401 || response.status == 403) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(assignee => {
          commit('setAssignee', assignee)
        })
        .catch(async (error) => {
          if (error.status == 401 || error.status == 403) {

            if (router.history.current.fullPath !== '/login') {
              router.push('/login')
            }
            commit('setAccessToken', '')
            localStorage.removeItem('token')
          }
        })
    },
   
    updateAccessToken({ commit }, token) {
      commit('setAccessToken', token)
      console.log(token)
      localStorage.setItem('token', token)
      router.push('/')
    },
    getReports({ commit, state }) {
      if (state.activeTeam) {
        fetch(
          `${process.env.VUE_APP_API_URL}standup/user/${state.activeTeam.teamId}`,
          {
            headers: { 'authorization': `Bearer ${state.accessToken}` }
          }
        ).then(response => {
          if (response.status == 401 || response.status == 403) {
            return Promise.reject(response);
          }
          return response.json();
        })
          .then(reports => {
            commit('setReports', reports)
          })
          .catch(async (error) => {
            if (error.status == 401 || error.status == 403) {

              if (router.history.current.fullPath !== '/login') {
                router.push('/login')
              }
              commit('setAccessToken', '')
              localStorage.removeItem('token')
            }
          })
      }

    },
    init({ dispatch }) {
      setTimeout(()=>{

        if (localStorage.getItem('token') != null) {
          let user = jwt_decode(localStorage.getItem('token'))
          dispatch('updateUserProfile', user)
        } else {
  
          setTimeout(() => {
          console.log(router.history.current)
           
          if (router.history.current.fullPath !== '/login') {
            router.push('/login')
          } 
          }, 1000);
  
        }
      },1000)
    }
  },
  modules: {
  }
})
