<template>
  <div>
    <h6 v-if="reports.length == 0">No reports available</h6>
    <div class="mt-3" v-for="report in reports" :key="report._id">
      <ReportItem :report="report" open="caret-down-fill" />
    </div>
  </div>
</template>

<script>
import ReportItem from "@/components/ReportItem.vue";
import { mapState } from 'vuex'

export default {
  data() {
    return {
      name: "ReportsList",
    };
  },
  components: {
    ReportItem,
  },
  props: {
    activeTeam: Object,
  },
  computed: {
    ...mapState(["reports"]),
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("getReports");
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
h6 {
  color: #424242;
  margin-top: 10px;
}
</style>
