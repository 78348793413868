<template>
    <section id="teams-bar">
        <div>
            <b-row>
                <b-col cols="8">
                    <p class="heading">My teams</p>
                </b-col>
                <b-col cols="4 text-right">
                    <!-- <p><a class="add-teams"><i class="fas fa-plus-circle"></i></a></p> -->
                </b-col>
            </b-row>

            <div class="team-wrapper active" v-for="myTeam in teams" :key="myTeam.id">
                <div class="flex-container">
                    <div class="image-wrappper">
                        <img v-bind:src="myTeam.image">
                    </div>
                    <div class="team_name">
                        <h6>{{myTeam.name}}</h6>
                        <p v-if="myTeam.users.length == 1">Private</p>
                        <p v-else>Private</p>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>
<script>

import { mapState } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['teams', 'activeTeam'])
  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">
    #teams-bar {
        width: 100%;
        background: #e5e6ef;
        min-height: calc(100vh - 88px);

        .heading {
            padding: 25px;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
        }

        .add-teams {
            color: #047bff;
            font-size: 18px;
            padding: 25px;
            margin-top: 25px;
        }

        .team-wrapper {
            padding: 25px;
        }

        .team-wrapper.active {
            background: #ffffff;
        }

        .image-wrappper img {
            height: 50px;
            width: 50px;
            border-radius: 5px;
        }

        .team_name {
            padding-left: 10px;
            font-weight: 300;
        }
    }
</style>
