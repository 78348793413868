<template>
  <div class="home">
    <Header />

    <div class="flex-container">
      <div class="sidebar">
        <TeamsBar/>
      </div>
      <div class="content">
        <div class="header"></div>

        <div><h5 v-if="activeTeam">{{activeTeam.name}}Dashboard</h5></div>
        <div class="reports">


          <b-row>

            <b-col sm="6"><h6>Reports</h6></b-col>
            <b-col sm="6" class="text-right"><b-button variant="primary"><router-link to="Reports">Create report</router-link></b-button></b-col>

          </b-row>

          <b-row>
            <b-col cols=12>
              <ReportsList :activeTeam="activeTeam" />
            </b-col>
          </b-row>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import TeamsBar from '@/components/TeamsBar.vue'
import ReportsList from '@/components/ReportsList.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    TeamsBar,
    ReportsList
  },
  computed: {
    ...mapState(['activeTeam'])
  },
  mounted () {
    this.activeTeam
  }
}
</script>

<style scoped lang="scss">
  .content {
    .header {
      width: 100%;
      height: 75px;
      display: block;
      border-bottom: 1px solid #ebebeb;
    }
    h5 {
      padding: 40px 40px 20pday
    }
    h6 {
      line-height: 38px;
      font-size: 24px;
      font-weight: 400;
    }
    .reports {
      padding: 20px 40px;
    }
    button a {
      color: #fff;
      text-decoration: none !important;
    }
    }
</style>
