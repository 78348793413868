<template>
  <div class="report-wrapper">
    <div class="row">
      <div class="col-md-8">
        <b-form-checkbox
          class="checkbox d-inline-block"
          @change="activate(report._id,$event)"
          v-model="report.active"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
        <h5 class="checkbox d-inline-block">
          <b-button
            class="title"
            :to="{ name: 'EditReport', params: { id: report._id }, query: {step: 1} }"
            variant="link"
          >
            <b>{{ report.title }}</b>
          </b-button>

          <b-button
            :to="{ name: 'EditReport', params: { id: report._id }, query: {step: 2} }"
            variant="link"
          >{{ getOccurance() }}</b-button>
        </h5>
      </div>
      <div class="col-md-4 text-right">
        <!-- <b-button :to="{ name: 'EditReport', params: { id: report._id } }" class="mr-2" variant="link"
          ><b-icon icon="gear-fill" aria-hidden="true"></b-icon
        ></b-button>-->
        <!-- <b-button  id="popover-target-1" class="mr-2" variant="link">
          ><b-icon icon="gear-fill" aria-hidden="true"></b-icon
        >-->
        <b-dropdown toggle-class="text-decoration-none" no-caret variant="link" class="m-md-2">
          <template #button-content>
            <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item
            :to="{ name: 'EditReport', params: { id: report._id } }"
            variant="link"
          >Edit report</b-dropdown-item>
          <b-dropdown-item @click="Deactivate(report._id)">De-/Activate report</b-dropdown-item>
          <b-dropdown-item @click="runReportNow(report._id)">Run report now</b-dropdown-item>
          <b-dropdown-item>Duplicate report</b-dropdown-item>
          <b-dropdown-item @click="openDeleteModel(report._id)">Delete report</b-dropdown-item>
        </b-dropdown>

        <b-modal ref="modal-1" title="Delete report">
          <p class="my-4">Do you really want to delete the report "{{report.title}}"</p>
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center">
              <b-button
                variant="light"
                size="sm"
                style="margin:0 10px; "
                @click="cancelReport()"
              >Cancel</b-button>
              <b-button
                variant="danger"
                size="sm"
                style="margin:0 10px;"
                @click="deleteReport(report._id)"
              >Delete report</b-button>
            </div>
          </template>
        </b-modal>

        <!-- </b-button> -->
        <b-button class="mr-2" variant="link">
          <b-icon icon="calendar3" aria-hidden="true"></b-icon>
        </b-button>
        <b-button @click="toggleView()" variant="link">
          <b-icon :icon="iconName" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </div>

    <div class="report-details" v-if="active">
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex">
            <div class="p-2">
              <b-icon class="text-black-50" font-scale="2" icon="person" aria-hidden="true"></b-icon>
            </div>
            <div class="flex-grow-1">
              <b-button
                :to="{ name: 'EditReport', params: { id: report._id }, query: {step: 2} }"
                class="mr-2 pl-0"
                size="lg"
                variant="link"
              >Respondents</b-button>
              <p class="text-black-50">
                {{ report.respondents.length }} people,
                {{ report.questions.length }} questions ({{
                getQuestionConditions()
                }}
                with conditions), {{ report.wait_time }}m waiting time
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 side-border">
          <div class="d-flex">
            <div class="p-2">
              <b-icon class="text-black-50" font-scale="2" icon="cursor" aria-hidden="true"></b-icon>
            </div>
            <div class="flex-grow-1">
              <b-button
                :to="{ name: 'EditReport', params: { id: report._id }, query: {step: 3} }"
                class="mr-2 pl-0"
                size="lg"
                variant="link"
              >Delivery</b-button>
              <p class="text-black-50">
                <span v-if="report.async_delivery">Asynchronously,</span>
                <span
                  v-if="report.delivery_users.length > 0"
                >{{ report.delivery_users.length }} people,</span>
                <span v-if="report.delivery_channels.length > 0">
                  <span></span>
                </span>
                <span v-if="report.delivery_emails.length > 0">
                  <span v-for="email in report.delivery_emails" :key="email">{{ email }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      boolean: 0,
      name: "ReportItem",
      active: true,
      iconName: "caret-down-fill",
      selectedReportId: null
    };
  },
  props: {
    report: Object,
    open: String,
    steps: String
  },

  computed: {
    ...mapState([
      "activeTeam",
      "userProfile",
      "accessToken",
      "projects",
      "assignee",
      "reports"
    ])
  },
  mounted() {},
  methods: {
    datetimestep() {
      console.log("daaa");
      this.$emit("linktosteps", this.steps);
    },
    runReportNow(id) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        }
      };
      let uri = `${process.env.VUE_APP_API_URL}standup/${id}/run`;
      try {
        axios.post(uri, {}, requestOptions).then(() => {
          this.$toast.open({
            message: "Report updated successfully",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top-right"
          });
        });
      } catch (error) {
        console.log("An error occured!");
        //  this.$refs["modal-1"].hide();
      }

      // this.$refs["modal-1"].show()
      console.log(id);
    },
    activate(id, event) {
      console.log(event);
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        }
      };

      {
        let uri = `${process.env.VUE_APP_API_URL}standup/activate/${id}`;
        console.log(uri);
        try {
          axios.post(uri, { active: event }, requestOptions).then(() => {
            let indexOfArrayItem = this.reports.findIndex(i => i._id === id);
            this.reports[indexOfArrayItem].active = event;
            this.active = event;
            // console.log("data",this.reports[indexOfArrayItem]);
          });
        } catch (error) {
          console.log("An error occured!");
        }
      }
    },
    Deactivate(id) {
      console.log(id);
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        }
      };

      {
        let uri = `${process.env.VUE_APP_API_URL}standup/deactivate/${id}`;
        console.log(uri);
        try {
          axios.post(uri, { active: false }, requestOptions).then(() => {
            let indexOfArrayItem = this.reports.findIndex(i => i._id === id);
            this.reports[indexOfArrayItem].active = false;
            this.active = false;
            // console.log("data",this.reports[indexOfArrayItem]);
          });
        } catch (error) {
          console.log("An error occured!");
        }
      }
    },

    openDeleteModel(id) {
      this.$refs["modal-1"].show();
      this.selectedReportId = id;
      console.log(id);
    },
    cancelReport() {
      this.$refs["modal-1"].hide();
    },
    deleteReport(id) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        }
      };

      {
        let uri = `${process.env.VUE_APP_API_URL}standup/delete/${id}`;
        try {
          axios.post(uri, {}, requestOptions).then(() => {
            let indexOfArrayItem = this.reports.findIndex(i => i._id === id);
            this.reports.splice(indexOfArrayItem, 1);
            this.$refs["modal-1"].hide();
          });
        } catch (error) {
          console.log("An error occured!");
          this.$refs["modal-1"].hide();
        }
      }
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    getOccurance() {
      let newDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      // let date = [];
      let occurance = "";
      let days = this.report.send_days;
      let date = days.map(d => new Date(d).getDay());
      if (days.length == 7) {
        occurance = "Everyday";
      } else if (days.length < 5 || days.length == 6) {
        for (let index = 0; index < days.length; index++) {
          const day =
            new Date(days[index]) != "Invalid Date"
              ? newDays[new Date(days[index]).getDay()]
              : days[index];
          occurance = occurance + day;
          if (index != days.length) {
            occurance = occurance + ",";
          }
        }
      } else if (
        date.includes(1) &&
        date.includes(2) &&
        date.includes(3) &&
        date.includes(4) &&
        date.includes(5)
      ) {
        occurance = "Mon-Fri";
      }

      return `${occurance}, ${this.tConvert(this.report.send_time)} (${
        this.report.recurring_type
      })`;
    },
    getQuestionConditions() {
      let count = 0;
      this.report.questions.forEach(que => {
        if (que.conditions.length > 0) {
          count++;
        }
      });
      return count;
    },
    toggleView() {
      this.active = !this.active;
      if (this.active) {
        this.iconName = "caret-up-fill";
      } else {
        this.iconName = "caret-down-fill";
      }
    }
  }
};
</script>

<style lang="scss">
</style>