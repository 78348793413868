<template>
  <div>
    <header class="hdr_bg_color">
      <b-row class="row">
        <b-col md="6" class="lg_width">
          <div class="header_font">
            <router-link to="/">
              <h6>BOTLRS</h6>
            </router-link>
          </div>
        </b-col>
        <b-col md="6" class="icon_right icn_width">
          <div class="icon_pad">
            <span>
              <a href="#">
                <i class="fas fa-search"></i>
              </a>
            </span>
            <span v-if="router.history.current.name != 'Settings'">
              <a href="#">
                <i class="fas fa-bell"></i>
              </a>
            </span>
            <!-- <span v-if="loggedIn"><span class="active_user m_r_0 padd_0">{{userProfile.  }}</span></span> -->
            <span v-if="router.history.current.name != 'Settings'" class="m_r_0">
              <a href="#" class="user_hvr">
                <i class="fas fa-user"></i>
                <ul class="log_txt">
                  <div>
                    <div v-if="loggedIn">
                      <b-row>
                        <b-col md="6 text-right">
                          <!-- <a class="nav-link user_txt" @click.prevent="signOut">Logout</a> -->
                          <a class="nav-link user_txt" @click.prevent="setting">Settings</a>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-else>
                      <b-row>
                        <b-col md="6 text-right user_txt">
                          <ul class="log_reg">
                            <li>
                              <router-link to="login" class="user_txt">Login</router-link>
                            </li>
                            <li>
                              <router-link to="register" class="user_txt">Register</router-link>
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </ul>
              </a>
            </span>
          </div>
        </b-col>
      </b-row>
    </header>
  </div>
</template>
<script>
import { mapState } from "vuex";
import router from "../router/index";
export default {
  data() {
    return {
      router: router
    };
  },
  computed: {
    ...mapState(["userProfile", "loggedIn"])
  },
  methods: {
    // signOut () {
    //   router.push('/login')
    // }
    setting() {
      router.push("/settings");
    }
  },
  mounted() {
    console.log(this.loggedIn);
    console.log("router", router);
  }
};
</script>
